<template>
  <div>
    <b-button
      v-b-modal.motivo-modal
      variant="primary"
      @click="limparCampo"
    >
      <feather-icon
        icon="PlusIcon"
      />
      Motivo
    </b-button>
    <b-modal
      id="motivo-modal"
      titel="Cadastrar Motivo"
      ok-title="Cadastrar"
      cancel-title="Cancelar"
      size="ml"
      @ok="cadastrarMotivo"
    >
      <b-tabs
        v-model="tabIndex"
        justified
      >
        <b-tab>
          <template #title>
            <span class="font-weight-bold">Eventos</span>
          </template>
          <b-form-group
            label="Nome do Evento"
            class="mt-2"
          >
            <b-form-input
              v-model="evento.nome"
              type="text"
            />
          </b-form-group>
          <b-form-group
            label="Cor"
            class="mt-2"
          >
            <b-form-select
              v-model="evento.cor"
            />
          </b-form-group>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="font-weight-bold">Devolução</span>
          </template>
          <b-form-group
            label="Nome da Devolução"
            class="mt-2"
          >
            <b-form-input
              v-model="evento.nome"
              type="text"
            />
          </b-form-group>
          <b-form-group
            label="Cor"
            class="mt-2"
          >
            <b-form-select
              v-model="evento.cor"
            />
          </b-form-group>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="font-weight-bold">Pendência</span>
          </template>
          <b-form-group
            label="Nome da Pendência"
            class="mt-2"
          >
            <b-form-input
              v-model="evento.nome"
              type="text"
            />
          </b-form-group>
          <b-form-group
            label="Cor"
            class="mt-2"
          >
            <b-form-select
              v-model="evento.cor"
            />
          </b-form-group>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="font-weight-bold">Cancelamento Campanha</span>
          </template>
          <b-form-group
            label="Nome do Cancelamento"
            class="mt-2"
          >
            <b-form-input
              v-model="motivoCancelamentoCampanha.nome"
              type="text"
            />
          </b-form-group>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="font-weight-bold">Cancelamento Acordo</span>
          </template>
          <b-form-group
            label="Nome do Cancelamento"
            class="mt-2"
          >
            <b-form-input
              v-model="motivoCancelamentoAcordo.nome"
              type="text"
            />
          </b-form-group>
        </b-tab>
      </b-tabs>
    </b-modal>
    <b-card
      class="mt-2"
    >
      <b-tabs
        v-model="tabIndex1"
        justified
        pills
      >
        <b-tab>
          <template #title>
            <span class="font-weight-bold">Eventos</span>
          </template>
          <b-row>
            <b-col>
              <b-row align-h="between">
                <b-col
                  cols="1"
                >
                  <b-form-select
                    v-model="perPage"
                    :options="[10, 15, 20]"
                    size="sm"
                  />
                </b-col>
                <b-col
                  lg="8"
                >
                  <b-form-input
                    v-model="filter"
                    placeholder="Pesquisar"
                    size="sm"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              class="mt-2"
            >
              <b-table
                responsive
                bordered
                striped
                hover
                :filter="filter"
                :per-page="perPage"
                :current-page="currentPage"
                :fields="fieldsEventos"
                :items="itemsEventos"
                @row-clicked="rowClicked"
                @filtered="onFiltered"
              />
            </b-col>
            <b-col>
              <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="totalRows"
                align="center"
                size="sm"
              />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="font-weight-bold">Devolucao</span>
          </template>
          <b-row>
            <b-col>
              <b-row align-h="between">
                <b-col
                  cols="1"
                >
                  <b-form-select
                    v-model="perPage1"
                    :options="[10, 15, 20]"
                    size="sm"
                  />
                </b-col>
                <b-col
                  lg="8"
                >
                  <b-form-input
                    v-model="filter1"
                    placeholder="Pesquisar"
                    size="sm"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              class="mt-2"
            >
              <b-table
                responsive
                bordered
                striped
                hover
                :filter="filter1"
                :per-page="perPage1"
                :current-page="currentPage1"
                :fields="fieldsDevolucao"
                :items="itemsDevolucao"
                @row-clicked="rowClicked"
                @filtered="filderedDevolucao"
              />
            </b-col>
            <b-col>
              <b-pagination
                v-model="currentPage1"
                :per-page="perPage1"
                :total-rows="totalRows1"
                align="center"
                size="sm"
              />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="font-weight-bold">Pendencia</span>
          </template>
          <b-row>
            <b-col>
              <b-row align-h="between">
                <b-col
                  cols="1"
                >
                  <b-form-select
                    v-model="perPage2"
                    :options="[10, 15, 20]"
                    size="sm"
                  />
                </b-col>
                <b-col
                  lg="8"
                >
                  <b-form-input
                    v-model="filter2"
                    placeholder="Pesquisar"
                    size="sm"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              class="mt-2"
            >
              <b-table
                responsive
                bordered
                striped
                hover
                :filter="filter2"
                :per-page="perPage2"
                :current-page="currentPage2"
                :fields="fieldsPendencia"
                :items="itemsPendencia"
                @row-clicked="rowClicked"
                @filtered="filderedPendencia"
              />
            </b-col>
            <b-col>
              <b-pagination
                v-model="currentPage2"
                :per-page="perPage2"
                :total-rows="totalRows2"
                align="center"
                size="sm"
              />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="font-weight-bold">Cancelamento Campanha</span>
          </template>
          <b-row>
            <b-col>
              <b-row align-h="between">
                <b-col
                  cols="1"
                >
                  <b-form-select
                    v-model="perPage3"
                    :options="[10, 15, 20]"
                    size="sm"
                  />
                </b-col>
                <b-col
                  lg="8"
                >
                  <b-form-input
                    v-model="filter3"
                    placeholder="Pesquisar"
                    size="sm"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              class="mt-2"
            >
              <b-table
                responsive
                bordered
                striped
                hover
                :filter="filter3"
                :per-page="perPage3"
                :current-page="currentPage3"
                :fields="fieldsCancelamentoCampanha"
                :items="itemsCancelamentoCampanha"
                @row-clicked="rowClicked"
                @filtered="filderedCancelamentoCampanha"
              />
            </b-col>
            <b-col>
              <b-pagination
                v-model="currentPage3"
                :per-page="perPage3"
                :total-rows="totalRows3"
                align="center"
                size="sm"
              />
            </b-col>
          </b-row>
        </b-tab>
        <b-tab>
          <template #title>
            <span class="font-weight-bold">Cancelamento Acordo</span>
          </template>
          <b-row>
            <b-col>
              <b-row align-h="between">
                <b-col
                  cols="1"
                >
                  <b-form-select
                    v-model="perPage4"
                    :options="[10, 15, 20]"
                    size="sm"
                  />
                </b-col>
                <b-col
                  lg="8"
                >
                  <b-form-input
                    v-model="filter4"
                    placeholder="Pesquisar"
                    size="sm"
                  />
                </b-col>
              </b-row>
            </b-col>
            <b-col
              cols="12"
              class="mt-2"
            >
              <b-table
                responsive
                bordered
                striped
                hover
                :filter="filter4"
                :per-page="perPage4"
                :current-page="currentPage4"
                :fields="fieldsCancelamentoAcordo"
                :items="itemsCancelamentoAcordo"
                @row-clicked="rowClicked"
                @filtered="filteredCancelamentoAcordo"
              >
              <template #cell(acoes)="data">
                <b-row>
                  <b-col class="text-center">
                    <feather-icon
                    icon="EditIcon"
                      class="cursor-pointer"
                      @click="editCancelamentoAcordo(data.item.id)"
                    />
                  </b-col>
                </b-row>
              </template>
            </b-table>
            </b-col>
            <b-col>
              <b-pagination
                v-model="currentPage4"
                :per-page="perPage4"
                :total-rows="totalRows4"
                align="center"
                size="sm"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card>
    <b-modal
      id="editModal"
      ref="editModal"
      title="Detalhes Motivo"
      hide-footer
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          Nome:
          <b-form-input
            v-model="edit.nome"
            :disabled="edicao"
          />
        </b-col>
        <b-col
          cols="12"
          class="mb-2"
        >
          Cor:
          <b-form-input
            v-model="edit.cor"
            :disabled="edicao"
          />
        </b-col>
        <b-col>
          <b-form-checkbox
            v-model="edit.status"
            :disabled="edicao"
            switch
          />
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col>
          <b-button
            v-show="edicao"
            variant="outline-primary"
            @click="editar"
          >
            <feather-icon
              icon="EditIcon"
            />
            Editar
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="outline-danger"
            @click="deleteMotivo"
          >
            <feather-icon
              icon="TrashIcon"
            />
            Excluir
          </b-button>
        </b-col>
        <b-col>
          <b-button
            v-show="!edicao"
            variant="outline-secondary"
            @click="$refs.editModal.hide()"
          >
            <feather-icon
              icon="XIcon"
            />
            Cancelar
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="primary"
            @click="saveMotivo"
          >
            <feather-icon
              icon="SaveIcon"
            />
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="editModal"
      ref="editModalAcordo"
      title="Cancelamento Acordo"
      hide-footer
      :ok-title=" motivoCancelamento ? 'Atualizar' : 'Cadastrar'"
      cancel-title="Cancelar"
      cancel-variant="danger"
      no-close-on-backdrop
      @cancel="clearForm"
      @ok="motivoCancelamento ? updateCancelamentoAcordo() : submit()"
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          Nome:
          <b-form-input
            v-model="motivoCancelamentoAcordo.nome"
            :disabled="edicao"
          />
        </b-col>
      </b-row>
      <hr>
      <b-row>
        <b-col>
          <b-button
            v-show="edicao"
            variant="outline-primary"
            @click="editar"
          >
            <feather-icon
              icon="EditIcon"
            />
            Editar
          </b-button>
        </b-col>
        <b-col>
          <b-button
            v-show="!edicao"
            variant="outline-secondary"
            @click="$refs.editModalAcordo.hide()"
          >
            <feather-icon
              icon="XIcon"
            />
            Cancelar
          </b-button>
        </b-col>
        <b-col>
          <b-button
            variant="primary"
            @click="updateCancelamentoAcordo"
          >
            <feather-icon
              icon="SaveIcon"
            />
            Salvar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import {
  BCol,
  BRow,
  BCard,
  BFormInput,
  BFormSelect,
  BFormGroup,
  BFormCheckbox,
  BTable,
  BPagination,
  BButton,
  BModal,
  VBModal,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import axios from '@/../axios-auth'

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormCheckbox,
    BTable,
    BPagination,
    BButton,
    BModal,
    BTabs,
    BTab,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      edicao: true,
      edit: [],
      idAcordo: null,
      tabIndex: 1,
      tabIndex1: 1,
      idMotivo: null,

      evento: [],

      motivoCancelamentoAcordo: {
        nome: '',
      },
      devolucao: {
        nome: '',
        cor: '',
        status: true,
      },

      pendencia: {
        nome: '',
        cor: '',
        status: true,
      },
      motivoCancelamentoCampanha: {
        nome: '',
      },

      filter: null,
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      itemsEventos: [],
      fieldsEventos: [],

      filter1: null,
      perPage1: 10,
      totalRows1: 1,
      currentPage1: 1,
      itemsDevolucao: [],
      fieldsDevolucao: [],

      filter2: null,
      perPage2: 10,
      totalRows2: 1,
      currentPage2: 1,
      itemsPendencia: [],
      fieldsPendencia: [],

      filter3: null,
      filter4: null,
      perPage3: 10,
      perPage4: 10,
      totalRows3: 1,
      totalRows4: 1,
      currentPage3: 1,
      currentPage4: 1,
      itemsCancelamentoCampanha: [],
      itemsCancelamentoAcordo: [],
      fieldsCancelamentoCampanha: ['id', 'nome'],
      fieldsCancelamentoAcordo: [{ key: 'id', label: 'id', class: 'text-center' }, 'nome', { key: 'acoes', label: 'ações', class: 'text-center' }],
    }
  },
  created() {
    this.listarEventos()
    this.listarDevolucao()
    this.listarPendencia()
    this.listarCancelamentoCampanha()
    this.listarCancelamentoAcordo()
  },
  methods: {
    editar() {
      this.edicao = false
    },

    limparCampo() {
      this.evento = {
        nome: null,
        cor: null,
        status: true,
      }
      this.devolucao = []
      this.pendencia = []
      this.motivoCancelamentoCampanha.nome = ''
    },
    rowClicked(item) {
      this.idMotivo = null
      this.idMotivo = item.id
      this.getMotivo()
    },
    // LISTAGEM
    async listarEventos() {
      this.totalRows = null
      this.itemsEventos = []
      await axios.get('api/v1/motivo_evento', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(async res => {
        // eslint-disable-next-line array-callback-return
        await res.data.dados.map(evento => {
          this.itemsEventos.unshift({
            id: evento.id,
            nome: evento.nome,
            cor: evento.cor,
          })
        })
        this.totalRows = this.itemsEventos.length
      })
    },

    async listarDevolucao() {
      this.totalRows1 = null
      this.itemsDevolucao = []
      await axios.get('api/v1/motivo_devolucao', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(async res => {
        // eslint-disable-next-line array-callback-return
        await res.data.dados.map(devolucao => {
          this.itemsDevolucao.unshift({
            id: devolucao.id,
            nome: devolucao.nome,
            cor: devolucao.cor,
          })
        })
        this.totalRows1 = this.itemsDevolucao.length
      })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    filderedDevolucao(filteredItems) {
      this.totalRows1 = filteredItems.length
      this.currentPage1 = 1
    },
    filderedPendencia(filteredItems) {
      this.totalRows2 = filteredItems.length
      this.currentPage2 = 1
    },
    filderedCancelamentoCampanha(filteredItems) {
      this.totalRows3 = filteredItems.length
      this.currentPage3 = 1
    },
    filteredCancelamentoAcordo(filteredItems) {
      this.totalRows4 = filteredItems.length
      this.currentPage4 = 1
    },
    async listarPendencia() {
      this.totalRows2 = null
      this.itemsPendencia = []
      await axios.get('api/v1/motivo_pendencia/', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(async res => {
        // eslint-disable-next-line array-callback-return
        await res.data.dados.dados.map(pendencia => {
          this.itemsPendencia.unshift({
            id: pendencia.id,
            nome: pendencia.nome,
            cor: pendencia.cor,
          })
        })
        this.totalRows2 = this.itemsPendencia.length
      })
    },
    async listarCancelamentoCampanha() {
      this.totalRows3 = null
      this.itemsCancelamentoCampanha = []
      await axios.get('api/v1/motivo_cancelamento_campanha/', {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      }).then(async res => {
        // eslint-disable-next-line array-callback-return
        await res.data.dados.map(motivoCancelamento => {
          this.itemsCancelamentoCampanha.unshift(motivoCancelamento)
        })
        this.totalRows3 = this.itemsCancelamentoCampanha.length
      })
    },
    clearForm() {
      this.motivoCancelamentoAcordo.nome = ''
    },
    async listarCancelamentoAcordo() {
      this.totalRows4 = null
      this.itemsCancelamentoAcordo = []
      await axios.get('api/v1/motivo/cancela/acordo/', {
      }).then(async res => {
        // eslint-disable-next-line array-callback-return
        await res.data.dados.map(motivoCancelamento => {
          this.itemsCancelamentoAcordo.unshift(motivoCancelamento)
        })
        this.totalRows4 = this.itemsCancelamentoAcordo.length
      })
    },
    editCancelamentoAcordo(id) {
      this.idAcordo = id
      axios.get(`api/v1/motivo/cancela/acordo/edit/${this.idAcordo}`, {
      })
        .then(res => {
          this.motivoCancelamentoAcordo = {
            nome: res.data.dados.nome,
            id: res.data.dados.id,
          }
          this.$refs.editModalAcordo.show()
        })
        .catch(() => {
          this.clearForm()
          this.$swal({
            title: 'Ops, algo deu errado =(',
            icon: 'danger',
            timer: 1500,
            showConfirmButton: false,
          })
        })
    },
    updateCancelamentoAcordo() {
      axios.put(`api/v1/motivo/cancela/acordo/update/${this.idAcordo}`, this.motivoCancelamentoAcordo, {
        headers: {
          Authorization: 'Bearer '.concat(localStorage.getItem('token')),
          accept: 'application/json',
        },
      })
        .then(() => {
          this.clearForm()
          this.listarCancelamentoAcordo()
          this.$swal({
            title: 'Cancelamento acordo atualizado com sucesso!',
            icon: 'success',
            timer: 1500,
            showConfirmButton: false,
          })
          this.$refs.editModalAcordo.hide()
        })
        .catch(() => {
          this.clearForm()
          this.listarCancelamentoAcordo()
          this.$swal({
            title: 'Ops, algo não foi possível atualizar =(',
            icon: 'danger',
            timer: 1500,
            showConfirmButton: false,
          })
        })
    },

    async getMotivo() {
      switch (this.tabIndex1) {
        default:
          break
        case 0:
          this.edit = []
          this.edicao = true
          await axios.get(`api/v1/motivo_evento/edit/${this.idMotivo}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(res => {
            this.edit = {
              id: res.data.dados.motivoEvento.id,
              nome: res.data.dados.motivoEvento.nome,
              cor: res.data.dados.motivoEvento.cor,
              status: res.data.dados.motivoEvento.status,
            }
            this.$refs.editModal.show()
          })
          break

        case 1:
          this.edit = []
          this.edicao = true
          await axios.get(`api/v1/motivo_devolucao/edit/${this.idMotivo}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(res => {
            this.edit = {
              id: res.data.dados.motivo_devolucao.id,
              nome: res.data.dados.motivo_devolucao.nome,
              cor: res.data.dados.motivo_devolucao.cor,
              status: res.data.dados.motivo_devolucao.status,
            }
            this.$refs.editModal.show()
          })
          break

        case 2:
          this.edit = []
          this.edicao = true
          await axios.get(`api/v1/motivo_pendencia/edit/${this.idMotivo}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(res => {
            this.edit = {
              id: res.data.dados.motivo_pendencia.id,
              nome: res.data.dados.motivo_pendencia.nome,
              cor: res.data.dados.motivo_pendencia.cor,
              status: res.data.dados.motivo_pendencia.status,
            }
            this.$refs.editModal.show()
          })
          break
        case 3:
          this.edit = []
          this.edicao = true
          await axios.get(`api/v1/motivo_cancelamento_campanha/edit/${this.idMotivo}`, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(res => {
            this.edit = {
              id: res.data.dados.id,
              nome: res.data.dados.nome,
            }
            this.$refs.editModal.show()
          })
          break
      }
    },

    cadastrarMotivo() {
      switch (this.tabIndex) {
        default:
          break
        case 0:
          axios.post('api/v1/motivo_evento/store', this.evento, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(() => {
            this.listarEventos()
            this.$swal({
              icon: 'success',
              title: 'SUCESSO!',
              text: 'Evento cadastrado com sucesso!',
              timer: 1500,
              showConfirmButton: false,
            })
          }).catch(async error => {
            if (await error.response) {
              this.$swal({
                title: 'Erro ao Cadastrar Evento !',
                text: error.response.data.mensagem,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
          break
        case 1:
          axios.post('api/v1/motivo_devolucao/store', this.evento, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(() => {
            this.listarDevolucao()
            this.$swal({
              icon: 'success',
              title: 'SUCESSO!',
              text: 'Devolução cadastrada com sucesso!',
              timer: 1500,
              showConfirmButton: false,
            })
          }).catch(error => {
            if (error.response) {
              this.$swal({
                title: 'Erro ao Cadastrar  Devolução !',
                text: error.response.data.mensagem,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
          break
        case 2:
          axios.post('api/v1/motivo_pendencia/store', this.evento, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(() => {
            this.listarPendencia()
            this.$swal({
              icon: 'success',
              title: 'SUCESSO!',
              text: 'Pendência cadastrada com sucesso!',
              timer: 1500,
              showConfirmButton: false,
            })
          }).catch(error => {
            if (error.response) {
              this.$swal({
                title: 'Erro ao Cadastrar Pendencia !',
                text: error.response.data.mensagem,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
          break
        case 3:
          axios.post('api/v1/motivo_cancelamento_campanha/store', this.motivoCancelamentoCampanha, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(() => {
            this.listarCancelamentoCampanha()
            this.$swal({
              icon: 'success',
              title: 'SUCESSO!',
              text: 'Motivo Cancelamento cadastrado com sucesso!',
              timer: 1500,
              showConfirmButton: false,
            })
          }).catch(error => {
            if (error.response) {
              this.$swal({
                title: 'Erro ao Cadastrar o motivo de Cancelamento !',
                text: error.response.data.mensagem,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
          break
        case 4:
          axios.post('api/v1/motivo/cancela/acordo/store/', this.motivoCancelamentoAcordo, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(() => {
            this.listarCancelamentoAcordo()
            this.$swal({
              icon: 'success',
              title: 'SUCESSO!',
              text: 'Motivo Cancelamento Acordo cadastrado com sucesso!',
              timer: 1500,
              showConfirmButton: false,
            })
          }).catch(error => {
            if (error.response) {
              this.$swal({
                title: 'Erro ao Cadastrar o motivo de Cancelamento Acordo !',
                text: error.response.data.mensagem,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
          break
      }
    },

    async saveMotivo() {
      const bodyCancelamentoCampanha = {
        nome: this.edit.nome,
      }
      switch (this.tabIndex1) {
        default:
          break
        case 0:
          await axios.put(`api/v1/motivo_evento/update/${this.idMotivo}`, this.edit, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(() => {
            this.$refs.editModal.hide()
            this.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: 'Alteração slava com sucesso"!',
              timer: 1500,
              showConfirmButton: false,
            })
          }).catch(error => {
            if (error.response) {
              this.$swal({
                title: 'Erro ao Alterar Motivo Evento !',
                text: error.response.data.mensagem,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
          await this.listarEventos()
          break
        case 1:
          await axios.put(`api/v1/motivo_devolucao/update/${this.idMotivo}`, this.edit, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(() => {
            this.$refs.editModal.hide()
            this.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: 'Alteração slava com sucesso!',
              timer: 1500,
              showConfirmButton: false,
            })
          }).catch(async error => {
            if (await error.response) {
              this.$swal({
                title: 'Erro ao Alterar Motivo Devolução!',
                text: error.response.data.mensagem,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
          await this.listarDevolucao()
          break
        case 2:
          await axios.put(`api/v1/motivo_pendencia/update/${this.idMotivo}`, this.edit, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(() => {
            this.$refs.editModal.hide()
            this.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: 'Alteração slava com sucesso"!',
              timer: 1500,
              showConfirmButton: false,
            })
          }).catch(async error => {
            if (await error.response) {
              this.$swal({
                title: 'Erro ao Alterar Motivo Pendencia!',
                text: error.response.data.mensagem,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
          await this.listarPendencia()
          break
        case 3:
          await axios.put(`api/v1/motivo_cancelamento_campanha/update/${this.idMotivo}`, bodyCancelamentoCampanha, {
            headers: {
              Authorization: 'Bearer '.concat(localStorage.getItem('token')),
              accept: 'application/json',
            },
          }).then(() => {
            this.$refs.editModal.hide()
            this.listarCancelamentoCampanha()
            this.$swal({
              icon: 'success',
              title: 'Sucesso',
              text: 'Alteração slava com sucesso"!',
              timer: 1500,
              showConfirmButton: false,
            })
          }).catch(async error => {
            if (await error.response) {
              this.$swal({
                title: 'Erro ao Alterar Motivo Cancelamento!',
                text: error.response.data.mensagem,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
          })
          break
      }
    },

    async deleteMotivo() {
      this.$swal({
        title: 'Tem certeza?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim quero deletar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          switch (this.tabIndex1) {
            default:
              break
            case 0:
              await axios.delete(`api/v1/motivo_evento/delete/${this.idMotivo}`, {
                headers: {
                  Authorization: 'Bearer '.concat(localStorage.getItem('token')),
                  accept: 'application/json',
                },
              }).then(() => {
                this.$swal({
                  icon: 'success',
                  title: 'Excluido',
                  text: 'Motivo excluido com sucesso"!',
                  timer: 1500,
                  showConfirmButton: false,
                })
              }).catch(async error => {
                if (await error.response) {
                  this.$swal({
                    title: 'Erro ao Excluir Motivo Evento !',
                    text: error.response.data.mensagem,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              })
              await this.$refs.editModal.hide()
              await this.listarEventos()
              break
            case 1:
              await axios.delete(`api/v1/motivo_devolucao/delete/${this.idMotivo}`, {
                headers: {
                  Authorization: 'Bearer '.concat(localStorage.getItem('token')),
                  accept: 'application/json',
                },
              }).then(() => {
                this.$swal({
                  icon: 'success',
                  title: 'Excluido',
                  text: 'Motivo excluido com sucesso!',
                  timer: 1500,
                  showConfirmButton: false,
                })
              }).catch(async error => {
                if (await error.response) {
                  this.$swal({
                    title: 'Erro ao Excluir Motivo Devolução!',
                    text: error.response.data.mensagem,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              })
              await this.$refs.editModal.hide()
              await this.listarDevolucao()
              break
            case 2:
              await axios.delete(`api/v1/motivo_pendencia/delete/${this.idMotivo}`, {
                headers: {
                  Authorization: 'Bearer '.concat(localStorage.getItem('token')),
                  accept: 'application/json',
                },
              }).then(() => {
                this.$swal({
                  icon: 'success',
                  title: 'Excluido',
                  text: 'Motivo excluido com sucesso"!',
                  timer: 1500,
                  showConfirmButton: false,
                })
              }).catch(async error => {
                if (await error.response) {
                  this.$swal({
                    title: 'Erro ao Exluir Motivo Pendencia!',
                    text: error.response.data.mensagem,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              })
              await this.$refs.editModal.hide()
              await this.listarPendencia()
              break
            case 3:
              await axios.delete(`api/v1/motivo_cancelamento_campanha/delete/${this.idMotivo}`, {
                headers: {
                  Authorization: 'Bearer '.concat(localStorage.getItem('token')),
                  accept: 'application/json',
                },
              }).then(() => {
                this.$swal({
                  icon: 'success',
                  title: 'Excluido',
                  text: 'Motivo excluido com sucesso"!',
                  timer: 1500,
                  showConfirmButton: false,
                })
              }).catch(async error => {
                if (await error.response) {
                  this.$swal({
                    title: 'Erro ao Exluir Motivo Cancelamento!',
                    text: error.response.data.mensagem,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              })
              await this.$refs.editModal.hide()
              await this.listarCancelamentoCampanha()
              break
          }
        }
      })
    },
  },
}
</script>
